<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" @datachange="getList(true)"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 건강검진 -->
          <c-select
            required
            :comboItems="comboItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="heaCheckupPlanId"
            label="건강검진 계획"
            v-model="searchParam.heaCheckupPlanId"
            @datachange="datachange"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <q-btn-group flat class="col-12 cal-searchbtn">
            <q-btn color="grey-8" class="calMoveBtn" outline rounded icon="arrow_back_ios" label="이전달" @click="onPrev" />
            <q-btn color="grey-8" class="calMoveBtn" outline rounded :label="title" style="width: 200px" @click="onToday"/>
            <q-btn color="grey-8" class="calMoveBtn" outline rounded icon-right="arrow_forward_ios" label="다음달" @click="onNext" />
          </q-btn-group>
        </div>
      </template>
    </c-search-box>
    <div style="overflow: hidden;">
        <!-- :style="theme" -->
      <q-calendar
        ref="calendar"
        v-model="selectedDate"
        view="month"
        locale="ko-kr"
        :day-height="120"
        :disabled-days="disabledDays"
        :disabled-weekdays="[0]"
        :disabled-before="beforeDate"
        :disabled-after="afterDate"
        animated
        bordered
        @change="onChange"
        short-weekday-label
        short-month-label
      >
        <template #day="{ timestamp }">
          <q-btn-group flat class="cal-btn-inner">
            <q-btn
              v-if="!timestamp.disabled && editable"  
              icon="add"
              color="blue-custom"
              dense  
              size="5px"
              @click="clickReserveDetail(timestamp)">
              <q-tooltip anchor="bottom left" self="top left">
                <div class="tooltipCustomTop">
                  {{ '예약 등록하기' }}
                </div>
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="!timestamp.disabled && editable"  
              icon="groups"
              color="orange-custom"
              dense  
              size="5px"
              @click="allReserveDetail(timestamp)">
              <q-tooltip anchor="bottom left" self="top left">
                <div class="tooltipCustomTop">
                  {{ timestamp.date + ' 예약 전체로 보기' }}
                </div>
              </q-tooltip>
            </q-btn>
          </q-btn-group>
          <template v-for="(event, index) in getEvents(timestamp.date)">
            <q-badge
              v-if="index >= 0 && index <= 2"
              :key="index"
              style="width: 100%; cursor: pointer; height: 16px; max-height: 16px"
              :class="badgeClasses(event, 'day')"
              :style="badgeStyles(event, 'day')"
              @click="clickReserveDetail(event)"
            >
              <q-icon v-if="event.icon" :name="event.icon" class="q-mr-xs"></q-icon><span class="ellipsis">{{ event.title }}</span>
            </q-badge>
            <q-badge
              v-if="index === 3"
              outline color="green-custom"
              :key="index"
              :style="badgeStyles(event, 'day')"
              style="width: 100%; cursor: pointer; height: 16px; max-height: 16px"
              @click="allReserveDetail(timestamp)"
            >
              <q-icon v-if="event.icon" name="groups" class="q-mr-xs"></q-icon><span class="ellipsis">외 {{  getEvents(timestamp.date).length-3  + '명'}}</span>
              <q-tooltip anchor="bottom left" self="top left">
                <div class="tooltipCustomTop">
                  {{ '더보기' }}
                </div>
              </q-tooltip>
            </q-badge>
          </template>
        </template>
      </q-calendar>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import QCalendarm from '@quasar/quasar-ui-qcalendar'
import {QCalendar} from '@quasar/quasar-ui-qcalendar'
import "@quasar/quasar-ui-qcalendar/dist/index.css"

// const CURRENT_DAY = new Date()

// function getCurrentDay (day) {
//   const newDay = new Date(CURRENT_DAY)
//   newDay.setDate(day)
//   const tm = QCalendarm.parseDate(newDay)
//   return tm.date
// }

const reRGBA = /^\s*rgb(a)?\s*\((\s*(\d+)\s*,\s*?){2}(\d+)\s*,?\s*([01]?\.?\d*?)?\s*\)\s*$/

function textToRgb (color) {
  if (typeof color !== 'string') {
    throw new TypeError('Expected a string')
  }

  const m = reRGBA.exec(color)
  if (m) {
    const rgb = {
      r: Math.min(255, parseInt(m[2], 10)),
      g: Math.min(255, parseInt(m[3], 10)),
      b: Math.min(255, parseInt(m[4], 10))
    }
    if (m[1]) {
      rgb.a = Math.min(1, parseFloat(m[5]))
    }
    return rgb
  }
  return hexToRgb(color)
}

function hexToRgb (hex) {
  if (typeof hex !== 'string') {
    throw new TypeError('Expected a string')
  }

  hex = hex.replace(/^#/, '')

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  else if (hex.length === 4) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3]
  }

  const num = parseInt(hex, 16)

  return hex.length > 6
    ? { r: num >> 24 & 255, g: num >> 16 & 255, b: num >> 8 & 255, a: Math.round((num & 255) / 2.55) }
    : { r: num >> 16, g: num >> 8 & 255, b: num & 255 }
}

function luminosity (color) {
  if (typeof color !== 'string' && (!color || color.r === undefined)) {
    throw new TypeError('Expected a string or a {r, g, b} object as color')
  }

  const
    rgb = typeof color === 'string' ? textToRgb(color) : color,
    r = rgb.r / 255,
    g = rgb.g / 255,
    b = rgb.b / 255,
    R = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4),
    G = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4),
    B = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4)
  return 0.2126 * R + 0.7152 * G + 0.0722 * B
}

export default {
  name: 'check-up-reserve',
  components: {
    QCalendar,
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        heaCheckupPlanId: null,
        checkupTypeCd: '',
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        width: '80%',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      comboItems:[],
      editable: true,
      listUrl: '',
      title: '',
      dateFormatter: undefined,
      start: undefined,
      selectedDate: '',
      row: null,
      weekEvents: [],
      disabledDays: [],
      lunarList: '',
      beforeDate: '2023-01-01',
      afterDate: '2023-01-01',
      hospitalIds: null,
      events: [
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
   computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.reserve.list.url;
      this.lunarList = selectConfig.sop.safetycheck.result.lunar.url; // 음력 공휴일구하기
      // this.setCheckUp();
      // this.setHolidays();
      this.getList(true);
      // this.setHospital();
    },
    setCheckUp() {
      this.$http.url = selectConfig.hea.checkup.plan.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.searchParam.plantCd,
        checkupTypeCd: 'CUTC000001',
      }
      this.$http.request((_result) => {
        this.comboItems = [];
        this.$_.forEach(_result.data, _item => {
          this.comboItems.push({
            code: _item.heaCheckupPlanId,
            codeName: _item.checkupName,
            hospitalId: _item.hospitalId,
            checkupStartDate: _item.checkupStartDate,
            checkupEndDate: _item.checkupEndDate,
          })
        })
      },);

    },
    setHolidays() {
      // disabledDays
      let year = this.start.year;
      // 양력 공휴일 계산
      this.disabledDays = [ year + "-01-01", year + "-03-01", year + "-05-05", year + "-06-06", 
                            year + "-07-17", year + "-08-15", year + "-10-03", year + "-12-25"]; 

      this.$http.url = this.$format(this.lunarList, year);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        // 음력 공휴일 계산은 백엔드에서
        this.lunarHolidays = _result.data
        for (let i=0; i<_result.data.length; i++) {
          this.disabledDays.push(
            _result.data[i].substring(0, 4) + '-' + 
            _result.data[i].substring(4, 6) + '-' +
            _result.data[i].substring(6, 8)
          )
        }
        // this.disabledDays.push()
      },);
    },
    getList(_first) {
      if (_first) {
        this.searchParam.heaCheckupPlanId = null;
        this.beforeDate = '2023-01-01';
        this.afterDate = '2023-01-01';
        this.hospitalIds = null;
        this.setCheckUp();
        this.events = [];
      } else {
        this.setCheckUp();
        if (!this.searchParam.heaCheckupPlanId) {
          this.events = [];
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: 
            // item.equipmentNm + 
            '건강검진 계획을 선택하세요.', // 점검년도를 선택하세요
            type: 'warning', // success / info / warning / error
          });
          return;
        } else {
          this.$http.url = this.listUrl;
          this.$http.type = 'GET';
          this.$http.param = this.searchParam;
          this.$http.request((_result) => {
            this.events = [];
            this.$_.forEach(_result.data, _item => {
              this.events.push(
                {
                  userCount: _result.data.length,
                  title: _item.reserveUserName,
                  reserveId: _item.reserveId,
                  details: _item.hopsitalName,
                  date: _item.reserveDate,
                  month: _item.reserveMonth,
                  day: _item.reserveDay,
                  year: _item.reserveYear,
                  bgcolor: 'orange',
                  icon: 'person'
                },
              )
            })
          },);
        }
      }
    },
    setHospital() {
      this.$http.url = selectConfig.hea.hospital.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
      };
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          this.comboItems.push({
            code: _item.hospitalId,
            codeName: _item.hospitalName,
            hospitalLocation: _item.hospitalLocation,
          })
        })
      },);
    },
    datachange(data) {
      if (data.value !== null) {
        let idx = this.$_.findIndex(this.comboItems, {code: data.value})
        this.beforeDate = this.comboItems[idx].checkupStartDate
        this.afterDate = this.comboItems[idx].checkupEndDate
        this.hospitalIds = this.comboItems[idx].hospitalId
        this.getList(false);
      } else {
        this.getList(true);
      }
    },
    onChange ({ start }) {
      this.start = start
      this.updateTitle()
      // this.setHolidays();
    },
    onPrev () {
      this.$refs.calendar.prev()
      // this.setHolidays();
    },
    onNext () {
      this.$refs.calendar.next()
      // this.setHolidays();
    },
    onToday() {
      this.$refs.calendar.moveToToday()
      // this.setHolidays();
    },
    updateTitle () {
      this.title = this.start.year + '년 ' + this.start.month + '월'
    },
    isCssColor (color) {
      return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/)
    },

    badgeClasses (event, type) {
      const cssColor = this.isCssColor(event.bgcolor)
      const isHeader = type === 'header'
      return {
        [`text-white bg-${event.bgcolor}`]: !cssColor,
        'full-width': !isHeader && (!event.side || event.side === 'full'),
        'left-side': !isHeader && event.side === 'left',
        'right-side': !isHeader && event.side === 'right'
      }
    },

    badgeStyles (event, type, timeStartPos, timeDurationHeight) {
      const s = {}
      if (this.isCssColor(event.bgcolor)) {
        s['background-color'] = event.bgcolor
        s.color = luminosity(event.bgcolor) > 0.5 ? 'black' : 'white'
      }
      if (timeStartPos) {
        s.top = timeStartPos(event.time) + 'px'
      }
      if (timeDurationHeight) {
        s.height = timeDurationHeight(event.duration) + 'px'
      }
      s['align-items'] = 'flex-start'
      return s
    },
    
    getEvents (dt) {
      const currentDate = QCalendarm.parseTimestamp(dt)
      const events = []
      for (let i = 0; i < this.events.length; ++i) {
        let added = false
        if (this.events[i].date === dt) {
          if (this.events[i].time) {
            if (events.length > 0) {
              // check for overlapping times
              const startTime = QCalendarm.parseTimestamp(this.events[i].date + ' ' + this.events[i].time)
              const endTime = QCalendarm.addToDate(startTime, { minute: this.events[i].duration })
              for (let j = 0; j < events.length; ++j) {
                if (events[j].time) {
                  const startTime2 = QCalendarm.parseTimestamp(events[j].date + ' ' + events[j].time)
                  const endTime2 = QCalendarm.addToDate(startTime2, { minute: events[j].duration })
                  if (QCalendarm.isBetweenDates(startTime, startTime2, endTime2) || QCalendarm.isBetweenDates(endTime, startTime2, endTime2)) {
                    events[j].side = 'left'
                    this.events[i].side = 'right'
                    events.push(this.events[i])
                    added = true
                    break
                  }
                }
              }
            }
          }
          if (!added) {
            this.events[i].side = undefined
            events.push(this.events[i])
          }
        }
        else if (this.events[i].days) {
          // check for overlapping dates
          const startDate = QCalendarm.parseTimestamp(this.events[i].date)
          const endDate = QCalendarm.addToDate(startDate, { day: this.events[i].days })
          if (QCalendarm.isBetweenDates(currentDate, startDate, endDate)) {
            events.push(this.events[i])
            added = true
          }
        }
      }
      return events
    },
    clickReserveDetail(item) {
      this.popupOptions.title = '종합검진 예약상세'; // 
      this.popupOptions.param = {
        reserveDate: item ? item.date : '',
        reserveDay: item ? item.day : '',
        reserveMonth: item ? item.month : '',
        reserveYear: item ? item.year : '',
        reserveId: item ? item.reserveId : '',
        plantCd: this.searchParam.plantCd ? this.searchParam.plantCd : '',
        heaCheckupPlanId: this.searchParam.heaCheckupPlanId ? this.searchParam.heaCheckupPlanId : '',
        hospitalIds: this.hospitalIds
      };
      this.popupOptions.target = () => import(`${'./checkUpReserveDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    allReserveDetail(item) {
      this.popupOptions.title = item.date + ' 예약 상세'; // 
      this.popupOptions.param = {
        reserveDate: item ? item.date : '',
        plantCd: this.searchParam.plantCd ? this.searchParam.plantCd : '',
        heaCheckupPlanId: this.searchParam.heaCheckupPlanId ? this.searchParam.heaCheckupPlanId : '',
        hospitalIds: this.hospitalIds
      };
      this.popupOptions.target = () => import(`${'./checkUpAllReserveDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList(false);
    },
  }
};
</script>